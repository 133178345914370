import React, { createContext, useContext, useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronRight, Clock, Crown, Star, Calendar } from 'lucide-react';

// Context Creation
const LuxuryContext = createContext();

// Context Provider Component
const LuxuryProvider = ({ children, initialData }) => {
  const [modernDeals, setModernDeals] = useState(initialData?.modern || []);
  const [classicDeals, setClassicDeals] = useState(initialData?.classic || []);
  const [loading, setLoading] = useState(false);

  // Format countdown date
  const formatCountdown = (date) => {
    const d = new Date(date);
    return `${d.getDate()} ${d.toLocaleString('default', { month: 'short' })}`.toUpperCase();
  };

  const value = {
    modernDeals,
    classicDeals,
    setModernDeals,
    setClassicDeals,
    loading,
    formatCountdown
  };

  return (
    <LuxuryContext.Provider value={value}>
      {children}
    </LuxuryContext.Provider>
  );
};

// Custom Hook for using the context
const useLuxury = () => {
  const context = useContext(LuxuryContext);
  if (!context) {
    throw new Error('useLuxury must be used within a LuxuryProvider');
  }
  return context;
};

// Flip Card Component
const FlipCard = ({ char, style = "modern" }) => {
  const styles = {
    modern: "bg-white border-amber-200 text-amber-900",
    classic: "bg-rose-50 border-rose-300 text-rose-900"
  };

  return (
    <motion.div
      initial={{ rotateX: -90 }}
      animate={{ rotateX: 0 }}
      transition={{ duration: 0.6, type: "spring" }}
      className="relative w-14 h-16 perspective-1000"
    >
      <div className={`w-full h-full ${styles[style]} border rounded-lg shadow-lg flex items-center justify-center`}>
        <span className="text-3xl font-serif">{char}</span>
        <div className={`absolute inset-0 bg-gradient-to-b ${style === 'modern' ? 'from-amber-50/50' : 'from-rose-50/50'} to-transparent rounded-lg`} />
      </div>
    </motion.div>
  );
};

// Deal Card Component
const DealCard = ({ deal, style }) => {
  const colors = {
    modern: "border-amber-100 hover:border-amber-200",
    classic: "border-rose-100 hover:border-rose-200"
  };

  const textColors = {
    modern: "text-amber-900",
    classic: "text-rose-900"
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className={`bg-white rounded-lg p-4 border ${colors[style]} transition-colors`}
    >
      <div className="flex justify-between items-start">
        <div>
          <h4 className={`font-medium ${textColors[style]}`}>{deal.title}</h4>
          <p className="text-gray-500 text-sm mt-1">{deal.date}</p>
          {deal.discount && (
            <span className="inline-block mt-2 text-sm font-medium bg-gray-100 px-2 py-1 rounded">
              {deal.discount}% OFF
            </span>
          )}
        </div>
        <Calendar className={`${style === 'modern' ? 'text-amber-400' : 'text-rose-400'}`} size={20} />
      </div>
    </motion.div>
  );
};

// Modern Collection Component
const ModernCollection = () => {
  const { modernDeals, formatCountdown } = useLuxury();
  const startDate = modernDeals[0]?.startDate || new Date();
  
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="bg-gradient-to-b from-amber-50 to-white p-8 rounded-2xl shadow-lg"
    >
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-2">
          <Star className="text-amber-400" size={24} />
          <h2 className="text-2xl font-serif text-amber-900">Modern Elegance</h2>
        </div>
      </div>

      <div className="flex gap-2 mb-8">
        {formatCountdown(startDate).split('').map((char, index) => (
          char === ' ' ? (
            <div key={index} className="w-4" />
          ) : (
            <FlipCard key={index} char={char} style="modern" />
          )
        ))}
      </div>

      <div className="space-y-4 mb-6">
        {modernDeals.map((deal, index) => (
          <DealCard key={index} deal={deal} style="modern" />
        ))}
      </div>

      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        className="w-full bg-gradient-to-r from-amber-600 to-amber-500 text-white px-6 py-3 rounded-lg font-medium shadow-lg"
      >
        View Modern Collection
      </motion.button>
    </motion.div>
  );
};

// Classic Collection Component
const ClassicCollection = () => {
  const { classicDeals, formatCountdown } = useLuxury();
  const startDate = classicDeals[0]?.startDate || new Date();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="bg-gradient-to-b from-rose-50 to-white p-8 rounded-2xl shadow-lg border border-rose-100"
    >
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-2">
          <Crown className="text-rose-400" size={24} />
          <h2 className="text-2xl font-serif text-rose-900">Classic Heritage</h2>
        </div>
      </div>

      <div className="flex gap-2 mb-8">
        {formatCountdown(startDate).split('').map((char, index) => (
          char === ' ' ? (
            <div key={index} className="w-4" />
          ) : (
            <FlipCard key={index} char={char} style="classic" />
          )
        ))}
      </div>

      <div className="space-y-4 mb-6">
        {classicDeals.map((deal, index) => (
          <DealCard key={index} deal={deal} style="classic" />
        ))}
      </div>

      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        className="w-full bg-gradient-to-r from-rose-600 to-rose-500 text-white px-6 py-3 rounded-lg font-medium shadow-lg"
      >
        View Classic Collection
      </motion.button>
    </motion.div>
  );
};

// Main Component
const DualLuxuryShowcase = ({ initialData }) => {
  const [activeTab, setActiveTab] = useState('all');

  return (
    <LuxuryProvider initialData={initialData}>
      <div className="w-full bg-gray-50 py-16">
        <div className="max-w-6xl mx-auto px-4">
          <motion.div
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            className="text-center mb-12"
          >
            <h1 className="text-3xl md:text-4xl font-serif text-gray-900 mb-4">
              Upcoming Deals
            </h1>
            <p className="text-gray-600">
            Exciting savings and special offers ahead!
            </p>
          </motion.div>

          {/* <div className="flex justify-center mb-12">
            <div className="bg-white rounded-lg p-1 shadow-md">
              {['all', 'modern', 'classic'].map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`px-6 py-2 rounded-md text-sm font-medium transition-colors ${
                    activeTab === tab 
                      ? 'bg-gray-900 text-white' 
                      : 'text-gray-600 hover:text-gray-900'
                  }`}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>
          </div> */}

          <div className="grid md:grid-cols-2 gap-8">
            <AnimatePresence mode="wait">
              {(activeTab === 'all' || activeTab === 'modern') && (
                <ModernCollection />
              )}
              {(activeTab === 'all' || activeTab === 'classic') && (
                <ClassicCollection />
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </LuxuryProvider>
  );
};

export default DualLuxuryShowcase;