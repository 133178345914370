import React from 'react';
import c1 from "../../../../asstes/Footer/slikmark.jpg";
import c2 from "../../../../asstes/Footer/handloom.jpg";

const CertificationImages = () => {
  return (
    <div className="container mx-auto p-4">
      <div className="relative">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 items-center pb-4 justify-center">
          {/* Image 1 */}
          <div className="flex justify-center items-center w-full">
            <img
              src={c1}
              alt="Certification 1"
              className="w-full h-auto object-contain rounded-lg"
            />
          </div>

          {/* Image 2 */}
          <div className="flex justify-center items-center w-full">
            <img
              src={c2}
              alt="Certification 2"
              className="w-full h-auto object-contain rounded-lg"
            />
          </div>
        </div>

        {/* Text at the bottom */}
        <p className="absolute bottom-0 left-1/2 transform -translate-x-1/2 text-center text-lg mt-8  text-gray-400 bg-white w-full py-2">
        100% certified by indian standards
        </p>
      </div>
    </div>
  );
};

export default CertificationImages;
