
import axios from 'axios';
const DEPLOYED='https://app.kalaksha.com'
// const LOCALHOST='http://localhost:5454'
const LOCALHOST='http://192.168.206.205:5454'
export const API_BASE_URL = DEPLOYED

export const S3_BASE_URL = 'https://yasar-2024-25-client.s3.amazonaws.com/'

const api = axios.create({
  baseURL: API_BASE_URL,
});

const token = localStorage.getItem('jwt');

api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

api.defaults.headers.post['Content-Type'] = 'application/json';

export default api;
