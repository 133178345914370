import React from 'react';
import { Check } from 'lucide-react';

const ChecklistItem = ({ text }) => (
  <div className="flex items-center space-x-3 p-2">
    <div className="flex-shrink-0 w-6 h-6 border-2 border-green-500 bg-green-500 rounded flex items-center justify-center">
      <Check className="w-4 h-4 text-white" />
    </div>
    <span className="text-gray-700 text-sm sm:text-base">{text}</span>
  </div>
);

const ChecklistCard = () => {
  const items = [
    "Hassle Free 7 days Return",
    "Money Back guarantee",
    "100% Original Quality Assurance",
    "Dispatch today order only",
    "It will be delivered by date"
  ];

  return (
    <div className="w-full max-w-md mx-auto">
      <div className="bg-white rounded-xl shadow-sm p-4 sm:p-6 space-y-2">
        <div className="border-b pb-3 mb-3">
          <h2 className="text-lg sm:text-xl font-semibold text-gray-800">
            Order Assurance
          </h2>
        </div>
        
        <div className="space-y-1">
          {items.map((item, index) => (
            <ChecklistItem key={index} text={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChecklistCard;