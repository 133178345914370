import React, { useEffect, useState } from 'react';

const AnnouncementBar = () => {
  // Define your messages here
  const messages = [
    "🎉 Enjoy 40% OFF on your first purchase! Use Code: NEW10",
    "👗 Discover our latest saree collection!",
    "🌟 Limited-time offer: Buy 1 Get 1 on selected items!",
    "🚚 Free shipping on orders above ₹999!"
  ];

  // State to manage the current message index
  const [currentIndex, setCurrentIndex] = useState(0);

  // Auto-slide effect
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [messages.length]);

  return (
    <div className="relative w-full h-14 overflow-hidden bg-black px-8 text-sm font-medium text-white sm:px-10 lg:px-12">
      <div
        className="flex transition-transform duration-500 ease-in-out"
        style={{
          transform: `translateX(-${currentIndex * 100}%)`,
        }}
      >
        {messages.map((message, index) => (
          <div key={index} className="min-w-full h-14 font-bold  flex items-center justify-center">
            <p className="text-center">
              {message}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AnnouncementBar;
