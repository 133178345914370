import React from 'react';
import { Mail, Phone, MessageCircle, MapPin } from 'lucide-react'; // Import MapPin icon
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, Typography, Grid, Card, CardContent, Button, List, ListItem, ListItemText, Alert, AlertTitle, Container } from '@mui/material';
import { styled } from '@mui/system';

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#006655',
    },
    secondary: {
      main: '#ffffff',
    },
  },
});

const GradientBackground = styled(Box)(() => ({
  background: 'linear-gradient(90deg, rgba(240,255,253,1) 0%, rgba(225,255,250,1) 100%)',
  minHeight: '100vh',
  padding: theme.spacing(4),
}));

const StyledCard = styled(Card)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
}));

const ContactOption = ({ icon, title, value, href }) => (
  <StyledCard elevation={3}>
    <CardContent>
      <Box display="flex" alignItems="center" mb={2}>
        {React.cloneElement(icon, { style: { marginRight: 8, color: theme.palette.primary.main } })}
        <Typography variant="h6" color="primary">{title}</Typography>
      </Box>
      <Typography variant="body1" color={href ? 'primary' : 'textSecondary'}>
        {href ? (
          <a href={href} style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
            {value}
          </a>
        ) : (
          value
        )}
      </Typography>
    </CardContent>
  </StyledCard>
);

const ContactUs = () => {
  return (
    <ThemeProvider theme={theme}>
      <GradientBackground>
        <Container maxWidth="lg">
          <Box my={8}>
            <Box bgcolor="rgba(0, 0, 0, 0.9)" p={4} borderRadius={2} textAlign="center" mb={4}>
              <Typography variant="h3" component="h1" gutterBottom color="secondary">
                Get in touch with our support team
              </Typography>
              <Typography variant="subtitle1" color="secondary">
                Our business hours are 9AM-6PM IST Monday-Saturday.
              </Typography>
            </Box>

            <Grid container spacing={3} mb={4}>
              <Grid item xs={12} md={4}>
                <ContactOption
                  icon={<Mail />}
                  title="Email"
                  value="support@kalaksha.com"
                  href="mailto:support@kalaksha.com"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <ContactOption
                  icon={<Phone />}
                  title="Phone"
                  value="+91-97422 12377"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <ContactOption
                  icon={<MessageCircle />}
                  title="WhatsApp"
                  value="+91-97422 12377"
                  href="https://wa.me/919742212377"
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} mb={12}>
              <Grid item xs={12} md={12}>
                <ContactOption
                  icon={<MapPin />} // Use MapPin icon for address
                  title="Address"
                  value="No 74, 8 Cross near Anjiniar, Ittamadu, BSK 3 Stage, Karnataka, Bangalore - 560085"
                />
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="center" flexWrap="wrap" gap={2} mb={4}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Mail />}
                href="mailto:support@kalaksha.com"
              >
                Email Us
              </Button>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<MessageCircle />}
                href="https://wa.me/919742212377"
                sx={{ color: 'primary.main' }}
              >
                Chat on WhatsApp
              </Button>
            </Box>

            <Card elevation={3} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
              <CardContent>
                <Typography variant="h5" gutterBottom color="primary">
                  How to Request a Replacement
                </Typography>
                <List>
                  <ListItem>
                    <ListItemText primary="1. Contact our customer support via email, phone, or WhatsApp using the details above." />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="2. Provide your order number and describe the issue with the product." />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="3. Include photographic evidence of the damage (if applicable)." />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="4. Our team will review your request and get back to you with the next steps." />
                  </ListItem>
                </List>
                <Alert severity="info" sx={{ mt: 2 }}>
                  <AlertTitle>Important Note</AlertTitle>
                  Please ensure to notify us within 7 days of receiving your order for replacement eligibility.
                </Alert>
              </CardContent>
            </Card>
          </Box>
        </Container>
      </GradientBackground>
    </ThemeProvider>
  );
};

export default ContactUs;
