import React from "react";

const TermsConditions = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Terms & Conditions</h1>
      <p>
        Welcome to <strong>Kalaksha.com</strong>. By accessing or using this website, you agree to be bound by the following terms and conditions ("Terms of Service"). These terms are legally binding, and your continued use of this website implies your full acceptance of these Terms. Please take the time to review them carefully before engaging with any part of our services or making any purchases.
      </p>
      
      <h2 style={styles.subheading}>1. General Terms</h2>
      <p>
        You must be at least 18 years of age to access or make purchases on <strong>Kalaksha.com</strong>. By using this website, you represent and warrant that you have the legal capacity to enter into these Terms of Service. We reserve the right to modify, amend, or update these Terms at any time, with or without prior notice. It is your responsibility to regularly review the latest version of the Terms before making any purchases or continuing to use the website. Any modifications will take effect immediately upon being posted.
      </p>
      
      <h2 style={styles.subheading}>2. Product Information and Availability</h2>
      <p>
        We strive to provide accurate and up-to-date information regarding our products, including descriptions, images, and prices. However, the colors and materials of products displayed on your screen may vary slightly from the actual items due to screen calibration differences. All prices are listed in INR (Indian Rupees), and we provide a currency converter for international buyers. Prices are subject to change at any time without prior notice. Please note that all orders are contingent upon product availability. If an item you have ordered is out of stock, we will notify you promptly and offer a suitable alternative or a full refund.
      </p>
      
      <h2 style={styles.subheading}>3. Payment Terms</h2>
      <p>
        We offer a variety of secure payment options, including but not limited to credit cards, debit cards, UPI, and net banking, processed through trusted payment gateway <strong>Razorpay</strong>. Full payment is required at the time of checkout. Please ensure that all payment information provided is accurate, as incorrect information may lead to delays or cancellation of your order. No order will be processed until payment has been successfully verified.
      </p>

      <h2 style={styles.subheading}>4. Replacement Policy</h2>
      <p>
        We do not offer refunds under any circumstances. However, we provide replacements for products that arrive damaged or if the incorrect color or item was delivered. To be eligible for a replacement, you must notify us within 7 days of receiving the product and provide photographic evidence of the damage or error. Replacements are subject to product availability, and the process may take additional time depending on the nature of the request. Please reach out to our customer support team at <a href="mailto:support@kalaksha.com">support@kalaksha.com</a> to initiate the replacement process.
      </p>

      <h2 style={styles.subheading}>5. Intellectual Property Rights</h2>
      <p>
        All content on this website, including but not limited to text, graphics, logos, images, designs, and product descriptions, is the exclusive property of <strong>Kalaksha</strong> and is protected under Indian copyright and trademark laws. Unauthorized reproduction, distribution, or use of any content from this website, without explicit written permission, is strictly prohibited and may result in legal action. If you wish to use any of our content for purposes other than personal, non-commercial use, please contact us for approval.
      </p>
      
      <h2 style={styles.subheading}>6. Limitation of Liability</h2>
      <p>
        To the fullest extent permitted by law, <strong>Kalaksha</strong> will not be liable for any damages of any kind arising from your use of this website, including but not limited to direct, indirect, incidental, punitive, and consequential damages. We are not responsible for any third-party content, links, or services that may be linked to from our website.
      </p>
      
      <h2 style={styles.subheading}>7. Governing Law</h2>
      <p>
        These Terms and Conditions are governed by and construed in accordance with the laws of India. Any disputes or claims arising from the use of this website or from transactions made through <strong>Kalaksha.com</strong> shall be subject to the exclusive jurisdiction of the courts of Chennai, Tamil Nadu.
      </p>

      <h2 style={styles.subheading}>8. Contact Us</h2>
      <p>
        If you have any questions, concerns, or need further clarification regarding these Terms, please do not hesitate to contact us at <a href="mailto:support@kalaksha.com">support@kalaksha.com</a> or reach out via our customer service hotline at +91-97422 12377.
      </p>
    </div>
  );
};

const styles = {
  container: { padding: "50px 200px", fontFamily: "Arial, sans-serif", lineHeight: "1.8" },
  heading: { fontSize: "2.5em", marginBottom: "20px" },
  subheading: { fontSize: "1.8em", marginTop: "20px", marginBottom: "10px" },
};

export default TermsConditions;
