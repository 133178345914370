import React from 'react'
import DualLuxuryShowcase from './DualLuxuryShowcase';

const UpcomingDeals = () => {
    const initialData = {
        modern: [
          {
            title: "Modern Autumn Collection",
            date: "Limited Time Offer",
            startDate: "2024-10-31",
            discount: 25
          },

        ],
        classic: [
          {
            title: "Heritage Collection",
            date: "Special Release",
            startDate: "2024-11-11",
            discount: 20
          },

        ]
      };
  return (
    <div>
     <div className="min-h-screen bg-gray-50">
      <DualLuxuryShowcase initialData={initialData} />
    </div>
    </div>
  )
}

export default UpcomingDeals
