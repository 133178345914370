import React from 'react';
import rz from "../../../../asstes/Footer/razaor pay.png"; // Adjusted path for Razorpay logo// Adjusted path for Razorpay logo

const SecurePayment = () => {
  return (
    <div className="bg-white shadow-lg p-6 rounded-lg mt-8">
      {/* Razorpay Payment Section */}
      <div className="flex items-center justify-center space-x-4 mb-6">
        {/* Lock Icon */}
        <i className="fas fa-lock text-green-600 text-xl"></i>

        {/* Razorpay Logo */}
        <img 
          src={rz} 
          alt="Razorpay" 
          className="w-20 h-auto"
        />

        {/* Secure Payment Text */}
        <p className="text-sm text-green-600 ml-4">
          100% Secure Payments with Razorpay
        </p>
      </div>

      {/* Payment Methods Section */}
      <div className="flex justify-center space-x-8">
        {/* UPI Icon */}
        <div className="flex flex-col items-center">
          <i className="fas fa-mobile-alt text-gray-300 text-2xl"></i> {/* UPI Icon */}
          <p className="text-sm text-gray-600 mt-2">UPI</p>
        </div>

        {/* Credit/Debit Cards Icon */}
        <div className="flex flex-col items-center">
          <i className="fas fa-credit-card text-gray-300 text-2xl"></i> {/* Card Icon */}
          <p className="text-sm text-gray-600 mt-2">Cards</p>
        </div>

        {/* Net Banking Icon */}
        <div className="flex flex-col items-center">
          <i className="fas fa-university text-gray-300 text-2xl"></i> {/* Net Banking Icon */}
          <p className="text-sm text-gray-600 mt-2">Net Banking</p>
        </div>
      </div>
    </div>
  );
};

export default SecurePayment;
