import React from 'react';
import { Search, ShoppingBag, Menu, ChevronRight } from 'lucide-react';
import slide4 from '../../../Sliderimg/4slide.png'
import price from '../../../Sliderimg/price.jpg'

import type1 from '../../../Sliderimg/sim.png'
import type2 from '../../../Sliderimg/grd.png'
import Ourpromises from '../../promises/Ourpromises';

const HomeCat = () => {
  const navItems = ['DIAMOND', 'GOLD', 'GEMSTONE', 'UNCUT DIAMOND', 'PLATINUM', 'GOLD COINS', 'SILVER', 'WATCHES', 'GIFTS', 'JEWELLERY', 'GIFT CARDS', 'GOLD RATE'];
  
  const categories = [
    { title: 'Kanchipuram silks', image: slide4 },
    { title: 'soft silks', image: slide4 },
    { title: 'mysore silks ', image: slide4 },
    { title: 'silks', image: slide4 },
  ];
  const prices = [
    { title: 'Under ₹4k', image:price },
    { title: '₹4k - 10k', image: price },
    { title: '₹10k - 20k ', image: price },
    { title: 'Above ₹20k', image: price },
  ];

  const diwaliCollection = [
    { title: 'Statement Necklaces', price: '30,400', image: 'https://localnation.co.in/cdn/shop/products/t729hRxZQS.jpg?v=1695819518' },
    { title: 'Radiant Pendant Sets', price: '30,400', image: 'https://static.wixstatic.com/media/faf1ba_bc70dc9fa2ae4d05b264d966500fad7f~mv2.jpg/v1/fill/w_480,h_240,al_c,lg_1,q_80,enc_auto/faf1ba_bc70dc9fa2ae4d05b264d966500fad7f~mv2.jpg' },
    { title: 'Stunning Earrings', price: '10,244', image: 'https://www.parijatstore.com/cdn/shop/files/DSC05524_Custom.jpg?v=1717152803' },
    { title: 'Sleek Rings', price: '10,100', image: 'https://t3.ftcdn.net/jpg/09/05/87/90/360_F_905879026_6aZ0Cf3tSurY5Kq6mKIe8dwSpljBw3uw.jpgs' },
  ];

  return (
    <div className="min-h-screen bg-white">
      {/* Navigation */}
      {/* <nav className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between items-center h-16">
            <div className="flex items-center">
              <Menu className="h-6 w-6 mr-4" />
              <span className="text-xl font-semibold">Banner</span>
            </div>
            <div className="flex items-center space-x-4">
              <Search className="h-6 w-6" />
              <ShoppingBag className="h-6 w-6" />
            </div>
          </div>
        </div> */}
        {/* Navigation Items */}
        {/* <div className="overflow-x-auto">
          <div className="flex space-x-6 px-4 py-2 text-sm whitespace-nowrap">
            {navItems.map((item) => (
              <a key={item} href="#" className="text-gray-600 hover:text-gray-900">
                {item}
              </a>
            ))}
          </div>
        </div>
      </nav> */}

      {/* Pick Your Look Section */}
      <section className="max-w-7xl mx-auto px-4 py-8">
        <h2 className="text-2xl font-serif text-center mb-2">Pick Your Look</h2>
        <p className="text-center text-gray-600 mb-6">Embrace the golden look of the style</p>
        <div className="grid md:grid-cols-2 gap-6">
          <div 
            style={{
              backgroundImage: `url(${type1})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
           className="bg-button-green rounded-lg p-20 text-center">
            <h3 className="text-xl text-Floral-white mb-2">SIMPLE</h3>
          </div>
          <div
            style={{
            backgroundImage: `url(${type2})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            }}
           className="bg-button-green rounded-lg p-20  text-center">
            <h3 className="text-xl text-Floral-white mb-2">GRAND</h3>
          </div>
        </div>
      </section>

      {/* Shop By Category */}
      <section className="max-w-7xl mx-auto px-4 py-8">
        <h2 className="text-2xl font-serif text-center mb-2">Shop By Category</h2>
        <p className="text-center text-gray-600 mb-6">Explore the since saree to make moments</p>
        <div className="grid md:grid-cols-2 gap-6">
          {categories.map((category) => (
            <div key={category.title} className="relative rounded-lg overflow-hidden group">
              <img src={category.image} alt={category.title} className="w-full h-48 object-cover" />
              <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/60">
                <h3 className="text-white text-lg font-medium">{category.title}</h3>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="max-w-7xl mx-auto px-4 py-8">
  <h2 className="text-2xl font-serif text-center mb-2">Shop By Price</h2>
  <p className="text-center text-gray-600 mb-6">Explore the since saree to make moments</p>
  <div className="grid md:grid-cols-4 gap-6">
    {prices.map((category) => (
      <div key={category.title} className="relative rounded-lg overflow-hidden group">
        <img src={category.image} alt={category.title} className="w-auto  h-full object-cover" />
        <div className="absolute bottom-0 left-0 right-0 h-full p-4 bg-gradient-to-t from-black/60 flex items-center justify-center">
          <h3 className="text-white text-3xl font-bold text-center">{category.title}</h3>
        </div>
      </div>
    ))}
  </div>
</section>

<Ourpromises/>


      {/* Diwali Collection */}
      {/* <section className="max-w-7xl mx-auto px-4 py-8 bg-purple-50">
        <h2 className="text-2xl font-serif text-center mb-2">Diwali Diamond Jewellery</h2>
        <p className="text-center text-gray-600 mb-6">Sparkle through the festive nights with timeless diamonds</p>
        <div className="grid md:grid-cols-2 gap-6">
          <div className="relative rounded-lg overflow-hidden">
            <img src={diwaliCollection[0].image} alt="Statement Necklaces" className="w-full h-full object-cover" />
            <div className="absolute bottom-0 left-0 right-0 p-6 bg-gradient-to-t from-black/60">
              <h3 className="text-white text-2xl font-medium">{diwaliCollection[0].title}</h3>
            </div>
          </div>
          <div className="grid gap-6">
            {diwaliCollection.slice(1).map((item) => (
              <div key={item.title} className="relative rounded-lg overflow-hidden">
                <img src={item.image} alt={item.title} className="w-full h-full object-cover" />
                <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/60">
                  <h3 className="text-white text-lg font-medium">{item.title}</h3>
                  <p className="text-white/90">Starting from ₹{item.price}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default HomeCat;