import React, { useState , useRef } from 'react';
import { Search, ShoppingBag, Menu, ChevronRight, ChevronLeft, Heart, Star } from 'lucide-react';
import heroSlider5 from '../../../Sliderimg/heroSlider5.png'

const ProductCard = ({ product, large = false }) => (
  <div className={`group relative ${large ? 'p-4' : 'p-2'}`}>
    <div className="relative overflow-hidden rounded-lg">
      <img 
        src={product.image} 
        alt={product.name} 
        className="w-full object-cover "
      />
      <button className="absolute top-2 right-2 p-1 bg-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity">
        <Heart className="h-5 w-5 text-gray-600" />
      </button>
      {product.discount && (
        <div className="absolute top-2 left-2 bg-red-500 text-white px-2 py-1 rounded-md text-sm">
          {product.discount}% OFF
        </div>
      )}
    </div>
    <div className="mt-4">
      <h3 className="text-lg font-medium text-gray-900">{product.name}</h3>
      <div className="flex items-center mt-1">
        <div className="flex items-center">
          {[...Array(5)].map((_, i) => (
            <Star key={i} className="h-4 w-4 text-yellow-400 fill-current" />
          ))}
        </div>
        <span className="ml-2 text-sm text-gray-500">({product.reviews})</span>
      </div>
      <div className="mt-2 flex items-center">
        <span className="text-xl font-bold text-gray-900">₹{product.price.toLocaleString()}</span>
        {product.originalPrice && (
          <span className="ml-2 text-sm text-gray-500 line-through">
            ₹{product.originalPrice.toLocaleString()}
          </span>
        )}
      </div>
    </div>
  </div>
);

const ProductSlider = ({ title, subtitle, products }) => {
    const sliderRef = useRef(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(true);
  
    const updateScrollButtons = () => {
      const container = sliderRef.current;
      if (container) {
        setCanScrollLeft(container.scrollLeft > 0);
        setCanScrollRight(
          container.scrollLeft < container.scrollWidth - container.clientWidth
        );
      }
    };
  
    const scroll = (direction) => {
      const container = sliderRef.current;
      if (container) {
        const scrollAmount = direction === 'left' ? -400 : 400;
        container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        setTimeout(updateScrollButtons, 300); // Update after scroll animation
      }
    };
  
    return (
      <div className="relative py-8">
        <div className="flex justify-between items-center mb-6">
          <div className=' w-full flex flex-col justify-center items-center'>
            <h2 className="text-2xl font-serif">{title}</h2>
            {subtitle && <p className="text-gray-600 mt-1">{subtitle}</p>}
          </div>
          <div className="flex space-x-2">
            <button
              onClick={() => scroll('left')}
              className={`p-2 rounded-full transition-all duration-200 ${
                canScrollLeft 
                  ? 'bg-gray-100 hover:bg-gray-200 text-gray-800' 
                  : 'bg-gray-50 text-gray-300 cursor-not-allowed'
              }`}
              disabled={!canScrollLeft}
            >
              <ChevronLeft className="h-6 w-6" />
            </button>
            <button
              onClick={() => scroll('right')}
              className={`p-2 rounded-full transition-all duration-200 ${
                canScrollRight 
                  ? 'bg-gray-100 hover:bg-gray-200 text-gray-800' 
                  : 'bg-gray-50 text-gray-300 cursor-not-allowed'
              }`}
              disabled={!canScrollRight}
            >
              <ChevronRight className="h-6 w-6" />
            </button>
          </div>
        </div>
        <div className="relative">
          <div
            ref={sliderRef}
            className="flex overflow-x-auto gap-6 pb-4 scroll-smooth"
            style={{
              scrollbarWidth: 'none',  // Firefox
              msOverflowStyle: 'none',  // IE/Edge
              WebkitOverflowScrolling: 'touch'
            }}
            onScroll={updateScrollButtons}
          >
            {products.map((product) => (
              <div 
                key={product.id} 
                className="flex-none w-72 first:ml-0"
                style={{ scrollSnapAlign: 'start' }}
              >
                <ProductCard product={product} />
              </div>
            ))}
          </div>
          {/* Gradient masks for scroll fade effect */}
          <div 
            className={`absolute left-0 top-0 bottom-4 w-12 bg-gradient-to-r from-white to-transparent pointer-events-none transition-opacity duration-200 ${
              canScrollLeft ? 'opacity-100' : 'opacity-0'
            }`}
          />
          <div 
            className={`absolute right-0 top-0 bottom-4 w-12 bg-gradient-to-l from-white to-transparent pointer-events-none transition-opacity duration-200 ${
              canScrollRight ? 'opacity-100' : 'opacity-0'
            }`}
          />
        </div>
      </div>
    );
  };
  


const SareeGrid = ({ products }) => (
  <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
    {products.map((product) => (
      <ProductCard key={product.id} product={product} />
    ))}
  </div>
);

const HomeProducts = () => {
  // Sample product data
  const bestSellers = [
    {
      id: 1,
      name: "Diamond Pendant Set",
      price: 45999,
      originalPrice: 52999,
      discount: 15,
      reviews: 128,
      image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/VSS482-0_1730864536300.webp"
    },
    {
        id: 1,
        name: "Diamond Pendant Set",
        price: 45999,
        originalPrice: 52999,
        discount: 15,
        reviews: 128,
        image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/VSS482-0_1730864536300.webp"
      },
      {
        id: 1,
        name: "Diamond Pendant Set",
        price: 45999,
        originalPrice: 52999,
        discount: 15,
        reviews: 128,
        image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/VSS482-0_1730864536300.webp"
      },
      {
        id: 1,
        name: "Diamond Pendant Set",
        price: 45999,
        originalPrice: 52999,
        discount: 15,
        reviews: 128,
        image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/VSS482-0_1730864536300.webp"
      },
      {
        id: 1,
        name: "Diamond Pendant Set",
        price: 45999,
        originalPrice: 52999,
        discount: 15,
        reviews: 128,
        image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/VSS482-0_1730864536300.webp"
      },
    // Add more products...
  ];

  const newArrivals = [
    {
      id: 1,
      name: "Crystal Studded Necklace",
      price: 32999,
      reviews: 45,
      image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/OSS2167-0_1730821014286.webp"
    },
    {
        id: 1,
        name: "Crystal Studded Necklace",
        price: 32999,
        reviews: 45,
        image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/OSS2167-0_1730821014286.webp"
      },
      {
        id: 1,
        name: "Crystal Studded Necklace",
        price: 32999,
        reviews: 45,
        image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/OSS2167-0_1730821014286.webp"
      },
      {
        id: 1,
        name: "Crystal Studded Necklace",
        price: 32999,
        reviews: 45,
        image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/OSS2167-0_1730821014286.webp"
      },
      {
        id: 1,
        name: "Crystal Studded Necklace",
        price: 32999,
        reviews: 45,
        image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/OSS2167-0_1730821014286.webp"
      },
      {
        id: 1,
        name: "Crystal Studded Necklace",
        price: 32999,
        reviews: 45,
        image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/OSS2167-0_1730821014286.webp"
      },
    // Add more products...
  ];

  const dealOfTheDay = {
    id: 1,
    name: "Bridal Diamond Set",
    price: 149999,
    originalPrice: 199999,
    discount: 25,
    reviews: 89,
    endTime: "2024-01-01T00:00:00",
    image: "https://silksaga.in/cdn/shop/products/DSC_4987_1200x1200.jpg?v=1681206819"
  };

  const sarees = [
    {
      id: 1,
      name: "Banarasi Silk Saree",
      price: 15999,
      originalPrice: 19999,
      discount: 20,
      reviews: 256,
      image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/VSS482-0_1730864536300.webp"
    },
    {
        id: 1,
        name: "Banarasi Silk Saree",
        price: 15999,
        originalPrice: 19999,
        discount: 20,
        reviews: 256,
        image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/VSS482-0_1730864536300.webp"
      },
      {
        id: 1,
        name: "Banarasi Silk Saree",
        price: 15999,
        originalPrice: 19999,
        discount: 20,
        reviews: 256,
        image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/VSS482-0_1730864536300.webp"
      },
      {
        id: 1,
        name: "Banarasi Silk Saree",
        price: 15999,
        originalPrice: 19999,
        discount: 20,
        reviews: 256,
        image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/VSS482-0_1730864536300.webp"
      },
      {
        id: 1,
        name: "Banarasi Silk Saree",
        price: 15999,
        originalPrice: 19999,
        discount: 20,
        reviews: 256,
        image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/VSS482-0_1730864536300.webp"
      },
    // Add more sarees...
  ];

  return (
    <div className="min-h-screen bg-white">
      {/* Previous navigation and banner sections remain the same */}
 {/* ============================================================================= */}  

      {/* Deal of the Day */}
      {/* <section className="max-w-7xl mx-auto px-4 py-8">
        <div className="bg-gradient-to-r from-purple-100 to-pink-100 rounded-lg p-8">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <span className="text-red-600 font-medium">Deal of the Day</span>
              <h2 className="text-3xl font-serif mt-2 mb-4">{dealOfTheDay.name}</h2>
              <div className="flex items-center space-x-4 mb-4">
                <span className="text-3xl font-bold">₹{dealOfTheDay.price.toLocaleString()}</span>
                <span className="text-xl text-gray-500 line-through">
                  ₹{dealOfTheDay.originalPrice.toLocaleString()}
                </span>
                <span className="bg-red-500 text-white px-2 py-1 rounded-md">
                  {dealOfTheDay.discount}% OFF
                </span>
              </div>
              <button className="bg-black text-white px-8 py-3 rounded-md hover:bg-gray-800">
                Shop Now
              </button>
            </div>
            <div className="relative">
              <img 
                src={dealOfTheDay.image} 
                alt={dealOfTheDay.name} 
                className="w-full rounded-lg"
              />
            </div>
          </div>
        </div>
      </section> */}

      {/* ============================================================================= */}

      {/* Best Sellers */}
      <section className="max-w-7xl mx-auto px-4">
        <ProductSlider 
          title="Best Sellers"
          subtitle="Our most popular products based on sales"
          products={bestSellers}
        />
      </section>

      {/* New Arrivals */}
      <section className="max-w-7xl mx-auto px-4">
        <ProductSlider 
          title="New Arrivals"
          subtitle="Check out our latest collection"
          products={newArrivals}
        />
      </section>
 
{/* ============================================================================= */}

      {/* Festival Vibes */}
      {/* <section className="max-w-7xl mx-auto px-4 py-8 bg-yellow-50">
        <h2 className="text-2xl font-serif text-center mb-2">Festival Vibes</h2>
        <p className="text-center text-gray-600 mb-6">Celebrate in style with our festive collection</p>
        <div className="grid md:grid-cols-3 gap-6"> */}
          {/* Festival category cards */}
          {/* {[1, 2, 3].map((i) => (
            <div key={i} className="relative rounded-lg overflow-hidden group">
              <img 
                src={`https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/820a6216940247.562b3638b21df.jpg`} 
                alt={`Festival category ${i}`}
                className="w-full h-64 object-cover"
              />
              <div className="absolute inset-0 bg-black/30 group-hover:bg-black/40 transition-colors" />
              <div className="absolute bottom-0 left-0 right-0 p-6">
                <h3 className="text-white text-xl font-medium">Festival Category {i}</h3>
                <p className="text-white/90 mt-2">Starting from ₹9,999</p>
              </div>
            </div>
          ))}
        </div>
      </section> */}


    {/* ============================================================================= */}

      {/* Saree Collection */}
      <section className="max-w-7xl mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-6">
          <div className=' w-full flex flex-col justify-center items-center'>
            <h2 className="text-2xl font-serif">Exclusive Saree Collection</h2>
            <p className="text-gray-600 mt-1">Traditional and contemporary designs</p>
          </div>
          <button className="text-black hover:text-gray-600 flex items-center">
            View All <ChevronRight className="h-5 w-5 ml-1" />
          </button>
        </div>
        <SareeGrid products={sarees} />
      </section>


      <section className=" w-full flex flex-col justify-center items-center  mx-auto py-8">
         <h2 className="text-3xl mb-7 font-serif">Behind The Scence</h2>
         <img src={heroSlider5} alt="behind the scence" />

      </section>
    </div>
  );
};

export default HomeProducts;