import React from 'react';

const Mainlogo = ({ width }) => {
  return (
    <div>
      <img src="/logok.svg" alt="Company Logo" width={width} style={{ height: 'auto' }} />
    </div>
  );
}

export default Mainlogo;
