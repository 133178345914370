export const navigation = {
  categories: [
    {
      id: 'Sarees',
      name: 'Sarees',
      featured: [
        {
          name: 'New Arrivals',
          href: '/collections/new-arrivals',
          imageSrc: 'https://singhanias.in/cdn/shop/articles/blog2.jpg?v=1720607851',
          imageAlt: 'New arrivals in sarees.',
        },
        {
          name: 'Trending Sarees',
          href: '/collections/trending',
          imageSrc: 'https://blog.pothys.com/wp-content/uploads/2024/08/BB.jpg',
          imageAlt: 'Trending sarees collection.',
        },
      ],
      sections: [
        

        {
          id: 'Fabric',
          name: 'Fabric',
          items: [
            { name: 'Cotton', id: 'cotton_sarees', href: '/collections/sarees/cotton' },
            { name: 'Silk', id: 'silk_sarees', href: '/collections/sarees/silk' },
            { name: 'Linen', id: 'linen_sarees', href: '/collections/sarees/linen' },
            { name: 'Chiffon', id: 'georgette_sarees', href: '/collections/sarees/georgette' },
          
          ],
        },
        {
          id: 'Style',
          name: 'Style',
          items: [
            { name: 'FABRIC', id: 'cotton_sarees', href: '/collections/sarees/cotton' },
            { name: 'Silk Sarees', id: 'silk_sarees', href: '/collections/sarees/silk' },
            { name: 'Linen Sarees', id: 'linen_sarees', href: '/collections/sarees/linen' },
            { name: 'Georgette Sarees', id: 'georgette_sarees', href: '/collections/sarees/georgette' },
            { name: 'Party Wear Sarees', id: 'party_wear_sarees', href: '/collections/sarees/party' },
            { name: 'Kanjeevaram Sarees', id: 'kanjeevaram_sarees', href: '/collections/sarees/kanjeevaram' },
          
          ],
        },
        {
          id: 'Saree-Types',
          name: 'Saree-Types',
          items: [
            { name: 'FABRIC', id: 'cotton_sarees', href: '/collections/sarees/cotton' },
            { name: 'Silk Sarees', id: 'silk_sarees', href: '/collections/sarees/silk' },
            { name: 'Linen Sarees', id: 'linen_sarees', href: '/collections/sarees/linen' },
            { name: 'Georgette Sarees', id: 'georgette_sarees', href: '/collections/sarees/georgette' },
            { name: 'Party Wear Sarees', id: 'party_wear_sarees', href: '/collections/sarees/party' },
            { name: 'Kanjeevaram Sarees', id: 'kanjeevaram_sarees', href: '/collections/sarees/kanjeevaram' },
          
          ],
        },
        
      ],
    },
  ],
  pages: [
    { name: 'Shop', id: '/shop' ,  },
    { name: 'Kanchipuram sliks', id: '/about' },
    { name: 'New Launches', id: '/about' },
    { name: 'Offer Zone', id: '/about' },
    { name: 'Contact Us', id: '/contact' ,href:'/contact-us' },
  ],
};
