import React, { useState } from 'react';
import axios from 'axios';
const jwt = localStorage.getItem("jwt");

const FileUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);

  // Handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Handle file upload
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      setMessage('Please select a file to upload');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await axios.post('http://192.168.141.205:5454/api/admin/products/upload', 
        
        formData, {
        headers: {
          'Authorization': 'Bearer ' + jwt, // JWT
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      });

      setMessage(response.data);
      setUploadProgress(0);
      setSelectedFile(null); // Reset file input
    } catch (error) {
      setMessage(`Error: ${error.response?.data || 'File upload failed'}`);
    }
  };

  return (
    <div className="file-upload">
      <h2>Upload a File</h2>
      <form onSubmit={handleSubmit}>
        <input type="file" onChange={handleFileChange} />
        <button type="submit">Upload</button>
      </form>

      {uploadProgress > 0 && (
        <div>
          <progress value={uploadProgress} max="100"></progress>
          <span>{uploadProgress}%</span>
        </div>
      )}

      {message && <p>{message}</p>}
    </div>
  );
};

export default FileUpload;
