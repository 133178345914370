import React from 'react';
import { 
  Crown, 
  Factory, 
  BadgeCheck, 
  Heart 
} from 'lucide-react';

const Ourpromises = () => {
    const promises = [
        {
          icon: <Crown className="w-12 h-12 text-[#AD9A6A] fill-current" strokeWidth={1} />,
          title: 'Exclusive Design',
          description: 'Uniquely crafted by artisans for a one-of-a-kind piece.'
        },
        {
          icon: <Factory className="w-12 h-12 text-[#AD9A6A] fill-current" strokeWidth={1} />,
          title: 'Directly from Manufacture',
          description: 'Get jewelry straight from our factory, with no middlemen.'
        },
        {
          icon: <BadgeCheck className="w-12 h-12 text-[#AD9A6A] fill-current" strokeWidth={1} />,
          title: 'Best Price Challenge',
          description: 'We offer the best prices—find a better one, and we’ll match it.'
        },
        {
          icon: <Heart className="w-12 h-12 text-[#AD9A6A] fill-current" strokeWidth={1} />,
          title: 'Hand Crafted with Love',
          description: 'Crafted by skilled artisans with passion and care.'
        }
      ];
      

  return (
    <div className="py-10 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900">Kalaksha Promises</h2>
          <p className="mt-4 text-lg text-gray-600">The promises that we'll never break</p>
        </div>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
          {promises.map((promise, index) => (
            <div
              key={index}
              className="relative p-8 bg-white rounded-lg border border-gray-200 shadow-sm hover:shadow-md transition-shadow duration-300"
            >
              <div className="flex flex-col items-center text-center">
                <div className="flex-shrink-0 mb-4">
                  {promise.icon}
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  {promise.title}
                </h3>
                <p className="text-gray-500 text-xs">
                  {promise.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Ourpromises;