import { useState, useEffect } from "react";
import { Heart, Share2, ShoppingCart } from 'lucide-react';
import { RadioGroup } from "@headlessui/react";
import { useNavigate, useParams } from "react-router-dom";
import ProductReviewCard from "./ProductReviewCard";
import { Box, Button, Grid, LinearProgress, Rating } from "@mui/material";
import HomeProductCard from "../../Home/HomeProductCard";
import { useDispatch, useSelector } from "react-redux";
import { findProductById } from "../../../../Redux/Customers/Product/Action";
import { addItemToCart } from "../../../../Redux/Customers/Cart/Action";
import { getAllReviews } from "../../../../Redux/Customers/Review/Action";
import ProductGallery from "../../Product Image Gallery/ProductGallery";
import Ourpromises from "../../../promises/Ourpromises";
import StockBar from "./StockBar";
import SecurePayment from "./SecurePayment";
import CertificationImages from "./CertificationImages";
import ChecklistCard from "./ChecklistCard";

const reviews = { href: "#", average: 4, totalCount: 117 };

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProductDetails() {
  const [selectedSize, setSelectedSize] = useState();
  const [activeImage, setActiveImage] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { customersProduct } = useSelector((store) => store);
  const { productId } = useParams();
  const jwt = localStorage.getItem("jwt");
  
  // Initialize the first section as open
  const [openSections, setOpenSections] = useState({ 0: true });

  // Loading state
  const [loading, setLoading] = useState(true);

  const handleSubmit = () => {
    const data = { productId };
    dispatch(addItemToCart({ data, jwt }));
    navigate("/cart");
  };

  useEffect(() => {
    const data = { productId: Number(productId) };
    dispatch(findProductById(data)).then(() => {
      setLoading(false); // Set loading to false after data is fetched
    });
    dispatch(getAllReviews(productId));
  }, [productId]);

  // Loading indicator
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="loader">Loading...</div>
      </div>
    );
  }

  // Assuming product details are populated correctly after loading
  const productDetails = [
    {
      category: 'Saree Details',
      details: [
        { label: 'Brand', value: customersProduct.product.brand },
        { label: 'Product Number', value: customersProduct.product.productNumber },
        { label: 'Dimension', value: customersProduct.product.dimension },
        { label: 'Origin Place', value: customersProduct.product.originPlace },
        { label: 'Style', value: customersProduct.product.style },
        { label: 'Pattern', value: customersProduct.product.pattern },
        { label: 'Occasion', value: customersProduct.product.occasion },
        { label: 'Fabric', value: customersProduct.product.fabric },
        { label: 'Border Size', value: customersProduct.product.borderSize },
        { label: 'Border Style', value: customersProduct.product.borderStyle },
        { label: 'Zari', value: customersProduct.product.zari },
        { label: 'Weight', value: customersProduct.product.weight },
      ]
    },
    {
      category: 'Blouse Details',
      details: [
        { label: 'Blouse Color Family', value: customersProduct.product.blouseColorFamily },
        { label: 'Blouse Dimension', value: customersProduct.product.blouseDimension },
        { label: 'Blouse Style', value: customersProduct.product.blouseStyle },
        { label: 'Blouse Type', value: customersProduct.product.blouseType },
      ]
    },
    {
      category: 'Pallu Details',
      details: [
        { label: 'Pallu Color', value: customersProduct.product.palluColor },
        { label: 'Pallu Type', value: customersProduct.product.palluType },
        { label: 'Body Design', value: customersProduct.product.bodydesign }
      ]
    }
  ];

  // Toggle function
  const toggleSection = (index) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the current section
    }));
  };

  return (
    <>
      <nav aria-label="Breadcrumb">
        {/* Breadcrumb navigation code... */}
      </nav>
      <div className="w-[90%] mx-auto pt-8 px-20 py-8">
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Image Gallery */}
          <div className="lg:w-2/3">
            <ProductGallery imageUrls={customersProduct.product.imageUrls} />
          </div>

          {/* Product Details */}
          <div className="lg:w-[45%]">
            <span className="inline-flex items-center rounded-md bg-purple-lite px-4 py-1 mb-4 text-xs font-medium text-purple-dark ring-1 ring-inset ring-purple-700/10">
              {customersProduct.product.saleTag}
            </span>
            <h1 className="text-2xl font-bold mb-2">{customersProduct.product.title}</h1>
            <p className="text-lg text-gray-500 mb-4">Product SKU : {customersProduct.product.skuNumber}</p>
           <StockBar/>
            <p className="text-xl font-semibold mb-4">₹{customersProduct.product.price}</p>
            <p className="text-sm text-gray-500 mb-4">Inclusive of all taxes (For Customers in India)</p>

            {/* Promotion message */}
            <div className="bg-Floral-white text-orange-800 p-2 rounded mb-4">
              <p className="text-sm">Get this for ₹3598 with coupon NEW10 on your first purchase*</p>
            </div>

            {/* Action buttons */}
            <div className="flex items-center gap-4 mb-4">
              <button className="bg-button-green text-white px-4 py-2 rounded flex-grow" onClick={handleSubmit}>
                ADD TO BAG
              </button>
              <button className="border border-gray-300 p-2 rounded">
                <Share2 className="w-6 h-6" />
              </button>
            </div>

            {/* Description */}
            <div className="mt-8">
              <h2 className="text-lg font-semibold mb-2">Description</h2>
              <p className="text-sm text-gray-600">
                {customersProduct.product.description}
              </p>
            </div>

            {/* Product Details Table */}
{/* Product Details Table */}
<div className="mt-8 bg-white shadow-lg rounded-lg overflow-hidden">
  {productDetails.map((section, sectionIndex) => (
    <div key={sectionIndex} className="mb-4">
      <h3
        className="flex items-center justify-between border-b border-gray-200 text-lg font-semibold text-gray-800 px-6 py-3 cursor-pointer transition-colors duration-200"
        onClick={() => toggleSection(sectionIndex)}
      >
        {section.category}
        <span className="ml-2 text-gray-500">{openSections[sectionIndex] ? '-' : '+'}</span>
      </h3>

      {/* Toggleable section content */}
      <div
        className={`overflow-hidden transition-all duration-300 ease-in-out ${
          openSections[sectionIndex] ? 'max-h-screen' : 'max-h-0'
        }`}
      >
        <table className="w-full text-sm text-left bg-white border-t border-gray-200">
          <tbody>
            {section.details
              .filter(detail => detail.value && detail.value !== 'N/A') // Filter out rows with 'N/A' value
              .map((detail, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-50 transition-colors duration-150"
                >
                  <td className="px-6 py-3 font-medium text-gray-700 whitespace-nowrap">
                    {detail.label}
                  </td>
                  <td className="px-6 py-3 text-gray-600">{detail.value}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  ))}

</div>
<SecurePayment/>
<CertificationImages/>
<ChecklistCard/>
          </div>
        </div>
        <Ourpromises/>
      </div>
    </>
  );
}
