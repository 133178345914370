import React, { useEffect, useState } from 'react';
import { Heart, ShoppingBag, Search, Star, Plus, Minus, Filter, X, Check } from 'lucide-react';
import { API_BASE_URL } from '../config/api';
import axios from "axios"; 

// Moved constants outside component
const INITIAL_CATEGORIES = [
  { id: 'sarees', label: 'Sarees', color: '#FFF' },
  { id: 'blouses', label: 'Blouses', color: '#000' },
  { id: 'suit-set', label: 'Suit Set With Dupatta', color: '#FFF' },
  { id: 'kurtas', label: 'Kurtas', color: '#FFF' },
  { id: 'kurta-sets', label: 'Kurta Sets', color: '#FFF' },
  { id: 'dresses', label: 'Dresses', color: '#FFF' },
  { id: 'tunics', label: 'Tunics', color: '#FFF' },
  { id: 'coords', label: 'Co-Ords Sets', color: '#FFF' },
  { id: 'gowns', label: 'Gowns', color: '#FFF' },
  { id: 'lehenga', label: 'Lehenga Sets stitched', color: '#FFF' },
  { id: 'kaftans', label: 'Kaftans', color: '#FFF' },
  { id: 'kaftan-suits', label: 'Kaftan Suits', color: '#FFF' }

  
];



const INITIAL_COLORS = [
  { name: 'Red', value: 'red' },
  { name: 'Pink', value: 'pink' },
  { name: 'Orange', value: 'orange' },
  { name: 'Yellow', value: 'yellow' },
  { name: 'Green', value: 'green' },
  { name: 'Blue', value: 'blue' },
  { name: 'Purple', value: 'purple' },
  { name: 'Brown', value: 'brown' },
  { name: 'Black', value: 'black' },
  { name: 'White', value: 'white' },
  { name: 'Gold', value: 'gold' },
  { name: 'Silver', value: 'silver' },
  { name: 'Cream', value: '#FFFDD0' },
  
];
const INITIAL_FABRIC = ['Cotton', 'Silk', 'Linen', 'Chiffon'];
const INITIAL_STYLE = ['Traditional', 'Modern', 'Casual', 'Formal'];
const INITIAL_ZARI = ['Gold', 'Silver', 'Copper'];
const INITIAL_PATTERN = ['Floral', 'Geometric', 'Solid', 'Striped'];
var INITIAL_PRODUCTS = [
  {
    id: 1,
    name: "Embroidered Anarkali Suit",
    price: 15999,
    rating: 4.8,
    image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/OSS2167-0_1730821014286.webp",
    category: "Suit Set"
  },
  {
    id: 2,
    name: "Embroidered Anarkali Suit",
    price: 15999,
    rating: 4.8,
    image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/OSS2167-0_1730821014286.webp",
    category: "Suit Set"
  },
  {
    id: 3,
    name: "Embroidered Anarkali Suit",
    price: 15999,
    rating: 4.8,
    image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/OSS2167-0_1730821014286.webp",
    category: "Suit Set"
  },
  {
    id: 4,
    name: "Embroidered Anarkali Suit",
    price: 15999,
    rating: 4.8,
    image: "https://yasar-2024-25-client.s3.amazonaws.com/kalaksha/productimages/OSS2167-0_1730821014286.webp",
    category: "Suit Set"
  },
  // Add more products as needed...
];

// Custom Checkbox Component
const CustomCheckbox = ({ checked, onChange, className = "" }) => (
  <div 
    className={`w-5 h-5 border border-gray-300 rounded flex items-center justify-center transition-colors ${
      checked ? 'bg-black border-black' : 'bg-white'
    } ${className}`}
    onClick={onChange}
  >
    {checked && <Check className="w-3 h-3 text-white" />}
  </div>
);

// Filter Section Component
const FilterSection = ({ title, expanded, onToggle, children }) => (
  <div className="border-b border-gray-200 py-4">
    <div className="flex justify-between items-center mb-4 cursor-pointer" onClick={onToggle}>
      <h3 className="font-medium text-gray-900">{title}</h3>
      {expanded ? (
        <Minus className="w-4 h-4" />
      ) : (
        <Plus className="w-4 h-4" />
      )}
    </div>
    {expanded && children}
  </div>
);

const FestiveEditShop = () => {
  // States
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedFabric, setSelectedFabric] = useState([]);
  const [selectedStyle, setSelectedStyle] = useState([]);
  const [selectedZari, setSelectedZari] = useState([]);
  const [allProducts, setAllProducts] = useState(INITIAL_PRODUCTS);
  const [selectedPattern, setSelectedPattern] = useState([]);
  const [priceRange, setPriceRange] = useState([1200, 17000]);
  const [wishlist, setWishlist] = useState(new Set());

  useEffect(() => {
    const getpro = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/admin/products/allpublic`, {
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        });
        setAllProducts(response.data); // Set the data from the API
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    getpro(); // Call the async function
  }, []); 


  const toggleWishlist = (productId) => {
    setWishlist(prev => {
      const newWishlist = new Set(prev);
      if (newWishlist.has(productId)) {
        newWishlist.delete(productId);
      } else {
        newWishlist.add(productId);
      }
      return newWishlist;
    });
  };

  const [expandedSections, setExpandedSections] = useState({
    price: true,
    category: true,
    size: true,
    color: true
  });
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);

  // Handler functions
  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };
  const handleSelectionChange = (item, setSelected) => {
    setSelected(prev =>
      prev.includes(item) ? prev.filter(i => i !== item) : [...prev, item]
    );
  };

  const handlePriceChange = (e, index) => {
    const newValue = parseInt(e.target.value);
    setPriceRange(prev => {
      const newRange = [...prev];
      newRange[index] = newValue;
      return newRange;
    });
  };

  const getGradientStyle = (index) => {
    const percentage = ((priceRange[index] - 1200) / (17000 - 1200)) * 100;
    return {
      background: `linear-gradient(to right, 
        #4A90E2 0%, 
        #4A90E2 ${percentage}%, 
        #1ABC9C ${percentage}%, 
        #1ABC9C 100%)`
    };
  };

  const FiltersContent = () => (
    <div className="space-y-6">
<FilterSection 
  title="PRICE" 
  expanded={expandedSections.price}
  onToggle={() => toggleSection('price')}
>
<div className="px-4 w-full">
      <div className="relative pt-6">
        {/* Base Track */}
        <div className="absolute w-full h-1 bg-gray-200 rounded-full" />
        
        {/* Min Price Slider */}
        <input
          type="range"
          min={1200}
          max={17000}
          value={priceRange[0]}
          onChange={(e) => handlePriceChange(e, 0)}
          className="absolute w-full -top-2 h-1 appearance-none rounded-full cursor-pointer transition-all duration-300"
          style={getGradientStyle(0)}
        />
        
        {/* Max Price Slider */}
        <input
          type="range"
          min={1200}
          max={17000}
          value={priceRange[1]}
          onChange={(e) => handlePriceChange(e, 1)}
          className="absolute w-full -top-2 h-1 appearance-none rounded-full cursor-pointer transition-all duration-300"
          style={getGradientStyle(1)}
        />
        
        {/* Custom Thumb Styles */}
        <style jsx>{`
          input[type='range']::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 16px;
            height: 16px;
            background: black;
            border-radius: 50%;
            cursor: pointer;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            transition: transform 0.2s ease;
          }
          
          input[type='range']::-webkit-slider-thumb:hover {
            transform: scale(1.1);
          }
          
          input[type='range']::-moz-range-thumb {
            width: 16px;
            height: 16px;
            background: black;
            border-radius: 50%;
            cursor: pointer;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            transition: transform 0.2s ease;
            border: none;
          }
          
          input[type='range']::-moz-range-thumb:hover {
            transform: scale(1.1);
          }
        `}</style>
      </div>
      
      {/* Price Display */}
      <div className="flex justify-between mt-8 text-sm text-gray-700">
        <span>₹{priceRange[0].toLocaleString()}</span>
        <span>₹{priceRange[1].toLocaleString()}</span>
      </div>
    </div>
</FilterSection>


      <FilterSection 
        title="CATEGORY" 
        expanded={expandedSections.category}
        onToggle={() => toggleSection('category')}
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 px-4">
          {INITIAL_CATEGORIES.map(category => (
            <label
              key={category.id}
              className={`px-4 py-2 border cursor-pointer transition-colors ${
                selectedCategories.includes(category.id)
                  ? 'bg-black text-white'
                  : 'bg-white text-black hover:bg-gray-50'
              }`}
            >
              <div className="flex items-center justify-between">
                <span className="text-sm">{category.label}</span>
                <input
                  type="checkbox"
                  checked={selectedCategories.includes(category.id)}
                  onChange={() => {
                    setSelectedCategories(prev =>
                      prev.includes(category.id)
                        ? prev.filter(id => id !== category.id)
                        : [...prev, category.id]
                    )
                  }}
                  className="hidden"
                />
              </div>
            </label>
          ))}
        </div>
      </FilterSection>
      <FilterSection title="FABRIC" expanded={expandedSections.fabric} onToggle={() => toggleSection('fabric')}>
        <div className="px-4">
          {INITIAL_FABRIC.map(fabric => (
            <label key={fabric} className="flex items-center space-x-2 cursor-pointer">
              <CustomCheckbox checked={selectedFabric.includes(fabric)} onChange={() => handleSelectionChange(fabric, setSelectedFabric)} />
              <span>{fabric}</span>
            </label>
          ))}
        </div>
      </FilterSection>

      <FilterSection title="STYLE" expanded={expandedSections.style} onToggle={() => toggleSection('style')}>
        <div className="px-4">
          {INITIAL_STYLE.map(style => (
            <label key={style} className="flex items-center space-x-2 cursor-pointer">
              <CustomCheckbox checked={selectedStyle.includes(style)} onChange={() => handleSelectionChange(style, setSelectedStyle)} />
              <span>{style}</span>
            </label>
          ))}
        </div>
      </FilterSection>

      <FilterSection title="ZARI" expanded={expandedSections.zari} onToggle={() => toggleSection('zari')}>
        <div className="px-4">
          {INITIAL_ZARI.map(zari => (
            <label key={zari} className="flex items-center space-x-2 cursor-pointer">
              <CustomCheckbox checked={selectedZari.includes(zari)} onChange={() => handleSelectionChange(zari, setSelectedZari)} />
              <span>{zari}</span>
            </label>
          ))}
        </div>
      </FilterSection>


      <FilterSection 
        title="COLOUR" 
        expanded={expandedSections.color}
        onToggle={() => toggleSection('color')}
      >
        <div className="grid grid-cols-1 gap-2 px-4">
          {INITIAL_COLORS.map(color => (
            <label
              key={color.name}
              className="flex items-center justify-between py-2 cursor-pointer hover:bg-gray-50 rounded"
            >
              <div className="flex items-center space-x-3">
                <span 
                  className="w-5 h-5 rounded-full inline-block border border-gray-200"
                  style={{ backgroundColor: color.value }}
                ></span>
                <span className="text-sm">{color.name}</span>
              </div>
              <CustomCheckbox
                checked={selectedColors.includes(color.name)}
                onChange={() => {
                  setSelectedColors(prev =>
                    prev.includes(color.name)
                      ? prev.filter(name => name !== color.name)
                      : [...prev, color.name]
                  )
                }}
              />
            </label>
          ))}
        </div>
      </FilterSection>
    </div>
  );

  return (
    <div className="min-h-screen bg-white">
        <div className=" h-[250px] py-6 text-white bg-black w-full flex justify-center items-center">
        <div className=" flex justify-center flex-col items-center ">
          <h1 className="text-4xl md:text-5xl font-bold ">Summer Collection</h1>
          <p className="text-lg md:text-xl opacity-90">Discover our latest arrivals</p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 py-8">
        {/* Mobile Filter Button */}
        <div className="lg:hidden mb-4">
          <button
            onClick={() => setIsMobileFilterOpen(true)}
            className="flex items-center space-x-2 px-4 py-2 border border-gray-200 rounded"
          >
            <Filter className="w-4 h-4" />
            <span>Filters</span>
          </button>
        </div>

        {/* Mobile Filter Drawer */}
        {isMobileFilterOpen && (
          <div className="fixed inset-0 z-50 lg:hidden">
            <div 
              className="fixed inset-0 bg-black bg-opacity-25" 
              onClick={() => setIsMobileFilterOpen(false)} 
            />
            <div className="fixed inset-y-0 left-0 w-full max-w-xs bg-white p-6 overflow-y-auto">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-lg font-medium">Filters</h2>
                <button onClick={() => setIsMobileFilterOpen(false)}>
                  <X className="w-6 h-6" />
                </button>
              </div>
              <FiltersContent />
            </div>
          </div>
        )}
                <div className="mb-8">
          <h2 className="text-2xl md:text-3xl font-bold mb-2">Featured Products</h2>
          <p className="text-gray-600">Explore our collection of premium products</p>
        </div>
        

        <div className="flex gap-8">
          {/* Desktop Filters Sidebar */}
          <div className="hidden lg:block w-72 flex-shrink-0">
            <FiltersContent />
          </div>

          {/* Product Grid */}
          

          <div className="max-w-7xl mx-auto px-4 py-8">
  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-6">
    {allProducts.map(product => (
      <div 
        key={product.id} 
        className="bg-white rounded-xl shadow-sm hover:shadow-xl transition-shadow duration-300 overflow-hidden"
      >
        {/* Product Image Container */}
        <div className="relative aspect-square group">
          <img
            src={product.image}
            alt={product.name}
            className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
          />
          
          {/* New Badge */}
          {product.isNew && (
            <span className="absolute top-4 left-4 bg-blue-500 text-white text-sm px-3 py-1 rounded-full">
              New Arrival
            </span>
          )}
          
          {/* Wishlist Button */}
          <button
            onClick={() => toggleWishlist(product.id)}
            className="absolute top-4 right-4 p-2 rounded-full bg-white/80 backdrop-blur-sm hover:bg-white/90 transition-all duration-200 hover:scale-110"
          >
            <Heart 
              className={`w-5 h-5 ${wishlist.has(product.id) ? 'fill-red-500 text-red-500' : 'text-gray-600'}`}
            />
          </button>
        </div>

        {/* Product Details */}
        <div className="p-4">
          <div className="space-y-2">
            <p className="text-sm text-gray-500">{product.category}</p>
            <h3 className="font-semibold text-lg line-clamp-2">{product.name}</h3>
            
            {/* Rating */}
            {/* <div className="flex items-center gap-1">
              <Star className="w-4 h-4 fill-yellow-400 text-yellow-400" />
              <span className="text-sm text-gray-600">{product.rating}</span>
            </div> */}

            {/* Price */}
            <p className="text-xl font-bold text-gray-900">
              ₹{product.price.toLocaleString()}
            </p>
          </div>

          {/* Add to Cart Button */}
          <button className="mt-4 w-full bg-black text-white rounded-lg py-3 font-medium flex items-center justify-center gap-2 transition-all duration-200 hover:bg-gray-800 active:scale-95">
            <ShoppingBag className="w-5 h-5" />
            Add to Cart
          </button>
        </div>
      </div>
    ))}
  </div>
</div>



        </div>
      </div>
    </div>
  );
};

export default FestiveEditShop;