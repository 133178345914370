import React, { useState } from "react";
import {
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Paper,
  IconButton,
} from "@mui/material";
import { Camera, X } from "lucide-react";
import { useDispatch } from "react-redux";
import { createProduct } from "../../../Redux/Customers/Product/Action"; // Import the createProduct action
import "./CreateProductForm.css";
import { API_BASE_URL } from "../../../config/api";

// Initial saree data state
const initialSareeData = {
  images: [],
  brand: "",
  title: "",
  skuNumber: "",          // SKU Number
  productNumber: "",      // Product Number
  price: "",
  discountPercentage: "",
  discountedPrice: "",
  topLevelCategory: "Women",
  secondLevelCategory: "Saree",
  thirdLevelCategory: "",
  fabric: "",
  saleTag:"clearence sale",
  sareeColor: "",
  dimension: "5.3m * 1.1 METER",
  weight: "",             // Weight
  occasion: "",           // Occasion
  style: "",
  originPlace: "",
  blouseType: "",
  blouseDimension: "70cm * 1.1 meter",
  blouseColorFamily: "",
  blouseStyle: "",
  borderStyle: "",
  borderSize: "",
  zari: "",
  pattern: "",
  description:"",
  palluType: "",
  palluColor: "",
  rack: "",
  block: "",
  quantity: "",
};



const CreateSareeProductForm = () => {
  const [sareeData, setSareeData] = useState(initialSareeData);
  const dispatch = useDispatch();
  const [imageFiles, setImageFiles] = useState([]);
  const jwt = localStorage.getItem("jwt");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSareeData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const newImageFiles = [...imageFiles, ...files].slice(0, 6);
    setImageFiles(newImageFiles);
  
    const newImages = newImageFiles.map((file) => URL.createObjectURL(file));
    setSareeData((prevState) => ({
      ...prevState,
      images: newImages,
    }));
  };

  const removeImage = (index) => {
    const newImageFiles = imageFiles.filter((_, i) => i !== index);
    setImageFiles(newImageFiles);
  
    const newImages = newImageFiles.map((file) => URL.createObjectURL(file));
    setSareeData((prevState) => ({
      ...prevState,
      images: newImages,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("sssss"+JSON.stringify(sareeData));
    dispatch(createProduct({data:sareeData}))
    
  };

  return (
    <Box sx={{ maxWidth: 1200, margin: "auto", padding: 3 }}>
      <Paper elevation={3} sx={{ padding: 3 }}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          Add New Saree Product
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {/* Description Section */}
            <Grid item xs={12} md={8}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Saree Details
              </Typography>
              <TextField
                fullWidth
                label="Saree Name"
                name="title"
                value={sareeData.title}
                onChange={handleChange}
                sx={{ mb: 2 }}
              />

              <TextField
                fullWidth
                label="description"
                name="description"
                value={sareeData.description}
                onChange={handleChange}
                sx={{ mb: 2 }}
              />

               <TextField
                fullWidth
                label="Saree Color"
                name="sareeColor"
                value={sareeData.sareeColor}
                onChange={handleChange}
                sx={{ mb: 2 }}
              />

              <TextField
                fullWidth
                label="Saree Dimension"
                name="dimension"
                value={sareeData.dimension}
                onChange={handleChange}
                placeholder="e.g., 5.3m * 1.1 METER"
                sx={{ mb: 2 }}
              />
              <Grid container spacing={2}>
                {/* Product Category Field */}
                 <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>Product Category</InputLabel>
                    <Select
                      name="thirdLevelCategory"
                      value={sareeData.thirdLevelCategory}
                      onChange={handleChange}
                      label="thirdLevelCategory"
                    >
                      <MenuItem value="Kanchipuram">Kanchipuram</MenuItem>
                      <MenuItem value="Mysore Silk">Mysore Silk</MenuItem>
                      <MenuItem value="Banarasi">Banarasi</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> 

                {/* Brand Field */}
               <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Brand"
                    name="brand"
                    value={sareeData.brand}
                    onChange={handleChange}
                    multiline
                  />
                </Grid>
              </Grid> 



            </Grid>

            {/* Product Images Section */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Product Images
              </Typography>
              <Grid container spacing={1}>
                {sareeData.images.map((image, index) => (
                  <Grid item xs={4} key={index}>
                    <Box sx={{ position: "relative", height: 100, width: 100 }}>
                      <img
                        src={image}
                        alt={`Product ${index + 1}`}
                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                      />
                      <IconButton
                        sx={{ position: "absolute", top: 0, right: 0, bgcolor: "rgba(255,255,255,0.7)" }}
                        onClick={() => removeImage(index)}
                      >
                        <X size={16} />
                      </IconButton>
                    </Box>
                  </Grid>
                ))}
                {sareeData.images.length < 6 && (
                  <Grid item xs={4}>
                    <Button
                      variant="outlined"
                      component="label"
                      sx={{ height: 100, width: 100 }}
                    >
                      <Camera />
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        multiple
                        onChange={handleImageUpload}
                      />
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>

                {/* Category Section */}
                <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Blouse & Attributes
              </Typography>
              <Grid container spacing={2}>


                {/* Blouse Section */}
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel>Blouse Type</InputLabel>
                    <Select
                      name="blouseType"
                      value={sareeData.blouseType}
                      onChange={handleChange}
                      label="Blouse Type"
                    >
                      <MenuItem value="Stitched">Stitched</MenuItem>
                      <MenuItem value="Unstitched">Unstitched</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* Blouse Color Family Section */}
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Blouse Color Family"
                    name="blouseColorFamily"
                    value={sareeData.blouseColorFamily}
                    onChange={handleChange}
                  />
                </Grid>


                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Blouse Dimension"
                    name="blouseDimension"
                    value={sareeData.blouseDimension}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel>Blouse Style</InputLabel>
                    <Select
                      name="blouseStyle"
                      value={sareeData.blouseStyle}
                      onChange={handleChange}
                      label="Blouse Style"
                    >
                      <MenuItem value="Contrast">Contrast</MenuItem>
                      <MenuItem value="Matching">Matching</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* Quantity Section */}
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Quantity"
                    name="quantity"
                    type="number"
                    value={sareeData.quantity}
                    onChange={handleChange}
                    InputProps={{ inputProps: { min: 1 } }}  // Minimum quantity is set to 1
                  />
                </Grid>

                {/* Border Section */}
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel>Border Style</InputLabel>
                    <Select
                      name="borderStyle"
                      value={sareeData.borderStyle}
                      onChange={handleChange}
                      label="Border Style"
                    >
                      <MenuItem value="Self">Self</MenuItem>
                      <MenuItem value="Contrast">Contrast</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel>Border Size</InputLabel>
                    <Select
                      name="borderSize"
                      value={sareeData.borderSize}
                      onChange={handleChange}
                      label="Border Size"
                    >
                      <MenuItem value="Big">Big</MenuItem>
                      <MenuItem value="Small">Small</MenuItem>
                      <MenuItem value="Medium">Medium</MenuItem>
                      <MenuItem value="Very big">Very big</MenuItem>
                      <MenuItem value="No border">No border</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* Zari Section */}
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel>Zari</InputLabel>
                    <Select
                      name="zari"
                      value={sareeData.zari}
                      onChange={handleChange}
                      label="Zari"
                    >
                      <MenuItem value="Copper">Copper</MenuItem>
                      <MenuItem value="Gold">Gold</MenuItem>
                      <MenuItem value="Tested">Tested</MenuItem>
                      <MenuItem value="Pure">Pure</MenuItem>
                      <MenuItem value="German silver">German silver</MenuItem>
                      <MenuItem value="Without Zari">Without Zari</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>


                {/* Fabric Section */}
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel>Fabric</InputLabel>
                    <Select
                      name="fabric"
                      value={sareeData.fabric}
                      onChange={handleChange}
                      label="Fabric"
                    >
                      <MenuItem value="Silk">Silk</MenuItem>
                      <MenuItem value="Cotton">Cotton</MenuItem>
                      <MenuItem value="Crepe">Crepe</MenuItem>
                      <MenuItem value="Tissue">Tissue</MenuItem>
                      <MenuItem value="Tusser">Tusser</MenuItem>
                      <MenuItem value="Chiffon">Chiffon</MenuItem>
                      <MenuItem value="Soft Silk">Soft Silk</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>


                {/* Pattern Section */}
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel>Pattern</InputLabel>
                    <Select
                      name="pattern"
                      value={sareeData.pattern}
                      onChange={handleChange}
                      label="Pattern"
                    >
                      <MenuItem value="Checks">Checks</MenuItem>
                      <MenuItem value="Plain">Plain</MenuItem>
                      <MenuItem value="Embossed">Embossed</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* Pallu Section */}
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel>Pallu Type</InputLabel>
                    <Select
                      name="palluType"
                      value={sareeData.palluType}
                      onChange={handleChange}
                      label="Pallu Type"
                    >
                      <MenuItem value="Self">Self</MenuItem>
                      <MenuItem value="Contrast">Contrast</MenuItem>
                      <MenuItem value="Without Pallu">Without Pallu</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Pallu Color"
                    name="palluColor"
                    value={sareeData.palluColor}
                    onChange={handleChange}
                  />
                </Grid>

                {/* Style Section */}
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel>Style</InputLabel>
                    <Select
                      name="style"
                      value={sareeData.style}
                      onChange={handleChange}
                      label="Style"
                    >
                      <MenuItem value="Traditional">Traditional</MenuItem>
                      <MenuItem value="Modern">Modern</MenuItem>
                      <MenuItem value="Fusion">Fusion</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* Origin Section */}
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel>originPlace</InputLabel>
                    <Select
                      name="originPlace"
                      value={sareeData.originPlace}
                      onChange={handleChange}
                      label="originPlace"
                    >
                      <MenuItem value="Mysore">Mysore</MenuItem>
                      <MenuItem value="Kanchipuram">Kanchipuram</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>


              </Grid>
            </Grid>


            {/* Idenitity */}
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Idenitity
              </Typography>
              <Grid container spacing={2}>

                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Weight"
                    name="weight"
                    value={sareeData.weight}
                    onChange={handleChange}
                    placeholder="Enter weight"
                  />
                </Grid>

                {/* Occasion Field */}
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Occasion"
                    name="occasion"
                    value={sareeData.occasion}
                    onChange={handleChange}
                    placeholder="Enter occasion"
                  />
                </Grid>

                {/* SKU Number Field */}
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="SKU Number"
                    name="skuNumber"
                    value={sareeData.skuNumber}
                    onChange={handleChange}
                    placeholder="Enter SKU number"
                  />
                </Grid>

                {/* Product Number Field */}
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Product Number"
                    name="productNumber"
                    value={sareeData.productNumber}
                    onChange={handleChange}
                    placeholder="Enter product number"
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Rack"
                    name="rack"
                    value={sareeData.rack}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Block"
                    name="block"
                    value={sareeData.block}
                    onChange={handleChange}
                  />
                </Grid>


              </Grid>
            </Grid>



            {/* Pricing Section */}
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Pricing Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="PRICE"
                    name="price"
                    value={sareeData.price}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Discount Percentage"
                    name="discountPercentage"
                    value={sareeData.discountPercentage}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Discounted Price"
                    name="discountedPrice"
                    value={sareeData.discountedPrice}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>

           

            {/* Submit Button */}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                sx={{ mt: 3 }}
              >
                Add Product
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
};

export default CreateSareeProductForm;
