import React, { useEffect, useState } from 'react';

const StockBar = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true); // Trigger animation when the component mounts
  }, []);

  return (
    <p
      className={`text-lg font-semibold text-green-600 mb-4 flex items-center justify-start space-x-2 ${
        show ? 'animate-slideIn' : ''
      }`}
    >
      <span role="img" aria-label="rocket" className="text-xl">🚀</span>
      <span>In Stock & Ready to Ship!</span>
      <span role="img" aria-label="package" className="text-xl">📦</span>
    </p>
  );
};

export default StockBar;
