import React, { useState } from 'react';
import { S3_BASE_URL } from '../../../config/api';

const ProductGallery = ({ imageUrls = [] }) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomedImage, setZoomedImage] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const baseURL = S3_BASE_URL;
  const aspectRatio = 1459 / 2153;

  // Sorting function to sort image URLs by index
  const sortImageUrls = (imageUrls) => {
    return [...imageUrls].sort((a, b) => {
      const aNumber = parseInt(a.split('-')[1].split('_')[0]);
      const bNumber = parseInt(b.split('-')[1].split('_')[0]);
      return aNumber - bNumber;
    });
  };

  // Sorted image URLs
  const sortedImageUrls = sortImageUrls(imageUrls);

  const handleMouseMove = (e, index) => {
    const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
    const x = (e.clientX - left) / width;
    const y = (e.clientY - top) / height;
    setMousePosition({ x, y });
    setZoomedImage(index);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % sortedImageUrls.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + sortedImageUrls.length) % sortedImageUrls.length);
  };

  // Image labels based on index
  const imageLabels = [
    { index: 0, label: 'Saree front' },
    { index: 1, label: 'Pallu' },
    { index: 2, label: 'Body' },
    { index: 3, label: 'Blouse' },
    { index: 4, label: 'Design / Close Up' },
    { index: 5, label: 'Border' },
  ];

  return (
    <div className="w-full">
      {/* Desktop view without slider */}
      <div className="hidden md:grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2 gap-4">
        {sortedImageUrls.map((url, index) => (
          <div
            key={index}
            className="relative w-full overflow-hidden"
            style={{ aspectRatio }}
            onMouseMove={(e) => handleMouseMove(e, index)}
            onMouseEnter={() => setIsZoomed(true)}
            onMouseLeave={() => setIsZoomed(false)}
          >
            <img
              src={`${baseURL}${url}`}
              alt={`Product view ${index + 1}`}
              className="w-full h-full object-cover"
            />

            {isZoomed && zoomedImage === index && (
              <div className="absolute top-0 right-0 w-32 h-32 mt-2 mr-2 overflow-hidden rounded-lg border border-gray-200 bg-white">
                <img
                  src={`${baseURL}${url}`}
                  alt="Zoomed view"
                  className="w-full h-full object-cover"
                  style={{
                    transform: 'scale(2)',
                    transformOrigin: `${mousePosition.x * 100}% ${mousePosition.y * 100}%`,
                  }}
                />
              </div>
            )}

            {isZoomed && zoomedImage === index && (
              <div
                className="absolute w-16 h-16 rounded-full border-2 bg-white opacity-50 border-purple-500 pointer-events-none flex items-center justify-center"
                style={{
                  top: `${mousePosition.y * 100}%`,
                  left: `${mousePosition.x * 100}%`,
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <span className="text-purple-500 text-2xl font-bold">+</span>
              </div>
            )}

            {/* Label for the image */}
            <div className="absolute bottom-2 left-2 bg-black bg-opacity-50 text-white text-sm p-2 rounded">
              {imageLabels.find((label) => label.index === index)?.label}
            </div>
          </div>
        ))}
      </div>

      {/* Mobile and tablet view with slider */}
      <div className="block md:hidden relative w-full overflow-hidden" style={{ aspectRatio }}>
        <img
          src={`${baseURL}${sortedImageUrls[currentImageIndex]}`}
          alt={`Product view ${currentImageIndex + 1}`}
          className="w-full h-full object-cover"
          onMouseEnter={() => setIsZoomed(true)}
          onMouseLeave={() => setIsZoomed(false)}
          onMouseMove={(e) => handleMouseMove(e, currentImageIndex)}
        />

        <button
          onClick={prevImage}
          className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white text-gray-800 rounded-full p-2 shadow hover:bg-gray-100"
        >
          &lt;
        </button>
        <button
          onClick={nextImage}
          className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white text-gray-800 rounded-full p-2 shadow hover:bg-gray-100"
        >
          &gt;
        </button>

        <div className="flex justify-center mt-4">
          {sortedImageUrls.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentImageIndex(index)}
              className={`w-2 h-2 rounded-full mx-1 ${currentImageIndex === index ? 'bg-purple-500' : 'bg-gray-300'}`}
            />
          ))}
        </div>

        {/* Label for the image in mobile view */}
        <div className="absolute bottom-2 left-2 bg-black bg-opacity-50 text-white text-sm p-2 rounded">
          {imageLabels.find((label) => label.index === currentImageIndex)?.label}
        </div>
      </div>
    </div>
  );
};

export default ProductGallery;
