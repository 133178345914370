import React from "react";

const PrivacyPolicy = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Privacy Policy</h1>
      <p>
        At <strong>Kalaksha</strong>, your privacy is of utmost importance to us. We are committed to maintaining the confidentiality, integrity, and security of your personal information. This Privacy Policy explains how we collect, use, disclose, and protect your data when you access or use our website <strong>Kalaksha.com</strong> and any related services. By using our website, you consent to the practices described in this policy.
      </p>

      <h2 style={styles.subheading}>1. Information We Collect</h2>
      <p>
        We collect various types of information in connection with the services we provide to ensure seamless interaction with our website and to improve user experience. The types of information we collect include:
      </p>
      <ul>
        <li><strong>Personal Information:</strong> This includes your name, email address, phone number, billing and shipping addresses, and payment details when you place an order.</li>
        <li><strong>Technical Information:</strong> We collect information related to the device you use to access our website, including your IP address, browser type, operating system, device type, and device identifiers.</li>
        <li><strong>Usage Data:</strong> We track your interactions on our website, such as pages viewed, time spent on the site, clicks, and other similar data to improve site performance and provide personalized services.</li>
        <li><strong>Cookies and Tracking Technologies:</strong> We use cookies, pixel tags, and other tracking technologies to collect data about your browsing activities, preferences, and interactions with the website.</li>
      </ul>

      <h2 style={styles.subheading}>2. How We Use Your Information</h2>
      <p>
        The information we collect is used for the following purposes:
      </p>
      <ul>
        <li><strong>Order Processing and Fulfillment:</strong> To process and deliver your orders, provide order confirmations, and manage returns or replacements.</li>
        <li><strong>Customer Support:</strong> To provide customer service, respond to inquiries, and resolve any issues related to your purchase or use of our services.</li>
        <li><strong>Marketing and Promotional Activities:</strong> With your consent, we may send you emails regarding new products, special offers, or other promotions. You can opt out of these communications at any time.</li>
        <li><strong>Website Improvements:</strong> We analyze usage data to understand user behavior, improve website functionality, and enhance the overall user experience.</li>
        <li><strong>Legal Obligations:</strong> We may use your information to comply with legal requirements, such as tax laws, fraud prevention, and other regulations.</li>
      </ul>

      <h2 style={styles.subheading}>3. Sharing of Your Information</h2>
      <p>
        We value your privacy and do not sell or rent your personal information to third parties. However, we may share your data in the following situations:
      </p>
      <ul>
        <li><strong>Service Providers:</strong> We may share your information with trusted third-party service providers (e.g., payment processors, shipping partners, and IT service providers) to assist us in delivering our services to you.</li>
        <li><strong>Legal Compliance:</strong> We may disclose your information if required by law, such as to comply with a subpoena, legal process, or regulatory request.</li>
        <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of company assets, your information may be transferred to the acquiring entity.</li>
        <li><strong>With Your Consent:</strong> We may share your information with other parties as described to you at the time of collection or with your consent.</li>
      </ul>

      <h2 style={styles.subheading}>4. Payment Security</h2>
      <p>
        All payments made through our website are processed securely using industry-standard encryption protocols. We use <strong>Razorpay</strong> as our payment gateway, and your credit card, debit card, or banking details are never stored on our servers. Razorpay ensures the protection of your financial information by implementing stringent security measures, including encryption and fraud prevention systems.
      </p>

      <h2 style={styles.subheading}>5. Data Retention</h2>
      <p>
        We retain your personal data for as long as necessary to provide our services and fulfill the purposes outlined in this Privacy Policy. If you wish to request the deletion of your personal data, you may contact us at <a href="mailto:support@kalaksha.com">support@kalaksha.com</a>. However, we may retain certain information if necessary to comply with legal obligations, resolve disputes, or enforce our agreements.
      </p>

      <h2 style={styles.subheading}>6. Cookies and Tracking Technologies</h2>
      <p>
        We use cookies and other tracking technologies to personalize your experience, store your preferences, and track website traffic and usage. Cookies are small text files stored on your device by your browser. You can manage or disable cookies through your browser settings. However, please note that disabling cookies may affect the functionality and user experience of our website.
      </p>
      <p>We use the following types of cookies:</p>
      <ul>
        <li><strong>Essential Cookies:</strong> Necessary for the operation of our website and services.</li>
        <li><strong>Performance Cookies:</strong> Used to analyze how visitors use our website and improve its functionality.</li>
        <li><strong>Advertising Cookies:</strong> Used to deliver personalized advertisements based on your browsing behavior.</li>
      </ul>

      <h2 style={styles.subheading}>7. Your Data Protection Rights</h2>
      <p>
        You have the right to access, modify, or delete your personal information, subject to certain exceptions. You may also withdraw your consent to the processing of your data for marketing purposes at any time. To exercise any of these rights, please contact us at <a href="mailto:support@kalaksha.com">support@kalaksha.com</a>. We will respond to your request within a reasonable timeframe.
      </p>

      <h2 style={styles.subheading}>8. Third-Party Links</h2>
      <p>
        Our website may contain links to third-party websites or services that are not operated or controlled by us. We are not responsible for the privacy practices of these third-party websites, and we encourage you to review their privacy policies before providing any personal information.
      </p>

      <h2 style={styles.subheading}>9. Changes to this Privacy Policy</h2>
      <p>
        We reserve the right to update or modify this Privacy Policy at any time. Any changes will be posted on this page with an updated effective date. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your personal information.
      </p>

      <h2 style={styles.subheading}>10. Contact Us</h2>
      <p>
        If you have any questions or concerns regarding this Privacy Policy or our data practices, please feel free to contact us at <a href="mailto:support@kalaksha.com">support@kalaksha.com</a> or via our customer service hotline at +91-97422 12377.
      </p>
    </div>
  );
};

const styles = {
  container: { padding: "50px 200px", fontFamily: "Arial, sans-serif", lineHeight: "1.8" },
  heading: { fontSize: "2.5em", marginBottom: "20px" },
  subheading: { fontSize: "1.8em", marginTop: "20px", marginBottom: "10px" },
};

export default PrivacyPolicy;
