import React from "react";

const ShippingPolicy = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Shipping Policy</h1>
      <p>
        At Kalaksha, we aim to deliver your products in the fastest and most efficient manner possible. This Shipping Policy outlines our shipping process within India, including timelines, charges, and other important information. We are committed to keeping you informed every step of the way.
      </p>

      <h2 style={styles.subheading}>1. Shipping Time</h2>
      <p>
        For domestic orders within India, we offer delivery within 3-7 business days. Please keep the following in mind:
      </p>
      <ul>
        <li>Business days do not include weekends and public holidays.</li>
        <li>Unforeseen circumstances, such as natural disasters or local strikes, may cause delays beyond our estimated delivery times.</li>
        <li>You will receive a notification when your order has been shipped, along with an estimated delivery date.</li>
      </ul>

      <h2 style={styles.subheading}>2. Shipping Charges</h2>
      <p>
        Shipping charges depend on the weight, dimensions, and delivery location of your products. The following guidelines apply:
      </p>
      <ul>
        <li>Shipping fees will be calculated and displayed at checkout before you complete your purchase.</li>
        <li>Free shipping is available for orders above a certain value, which is specified on our website.</li>
        <li>For bulky items, additional shipping charges may apply, which will be communicated at checkout.</li>
      </ul>

      <h2 style={styles.subheading}>3. Delivery Partners</h2>
      <p>
        We work with reputed courier services within India to ensure timely and secure delivery of your products. Our delivery partners include:
      </p>
      <ul>
        <li>Blue Dart</li>
        <li>DTDC</li>
        <li>Delhivery</li>
        <li>India Post</li>
        <li>Professional Couriers</li>
      </ul>
      <p>
        Once your order is shipped, you will receive a tracking number via email or SMS, which you can use to track your package in real time.
      </p>

      <h2 style={styles.subheading}>4. Delayed or Lost Shipments</h2>
      <p>
        If your shipment is delayed or lost, please contact our support team for assistance. Here’s how we will help:
      </p>
      <ul>
        <li>Contact our customer support team via email or phone as soon as possible.</li>
        <li>Provide your order number and details about the issue.</li>
        <li>We will coordinate with the courier company to track your shipment and resolve any issues as quickly as possible.</li>
        <li>If a shipment is confirmed lost, we will arrange for a replacement or refund as per our policy.</li>
      </ul>

      <h2 style={styles.subheading}>5. Important Notes</h2>
      <p>
        To ensure smooth delivery of your orders, please make sure to:
      </p>
      <ul>
        <li>Provide accurate shipping information during checkout.</li>
        <li>Be available at the shipping address to receive the package.</li>
        <li>Contact us if you need to change your shipping address after placing an order.</li>
      </ul>
      <p>
        Thank you for choosing Kalaksha! We appreciate your trust in us and are committed to delivering your products promptly and safely.
      </p>
    </div>
  );
};

const styles = {
  container: { padding: "50px 200px", fontFamily: "Arial, sans-serif", lineHeight: "1.8" },
  heading: { fontSize: "2.5em", marginBottom: "20px" },
  subheading: { fontSize: "1.8em", marginTop: "20px", marginBottom: "10px" },
};

export default ShippingPolicy;
